import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
} from '@wix/yoshi-flow-editor';
import type {
  AddComponentHandler,
  ElementData,
  RemoveComponentHandler,
} from '@wix/platform-editor-sdk';

import { toMonitored } from '../../../editor/services/monitor';
import { withRetry } from '../../../editor/services';
import { createCustomPanelOnClick } from '../../common/create-panel';
import { ElementId, HostWidgetId, WidgetName } from '../../../constants';
import { isProfilePageBobWidgetInstalled } from '../../../editor/services/page-ref';

import {
  getProfilePageManageAndNavigateInitialData,
  getSettingsPageManageAndNavigateInitialData,
} from './utils';
import {
  collapseRefComponent,
  expandRefComponentByRole,
} from '../../../editor/editor-sdk-wrappers';
import type {
  LayoutPanelInitialData,
  ManageAndNavigatePanelInitialData,
  OpenManageAndNavigatePanelOptions,
} from '../../../types';

const getElementsData = async (
  editorSDK: FlowEditorSDK,
  t: TFunction,
): Promise<ElementData[]> => {
  if (await isProfilePageBobWidgetInstalled(editorSDK)) {
    return [
      {
        label: t('app.page.settings.elements.panel.title'),
        identifier: { role: ElementId.HeaderContainer },
        index: 0,
      },
      {
        label: t('app.page.settings.elements.panel.member-menu'),
        identifier: { role: ElementId.MenuContainer },
        index: 1,
      },
    ];
  }

  return [
    {
      label: t('app.page.settings.elements.panel.title'),
      identifier: { role: ElementId.HeaderContainer },
      index: 0,
    },
  ];
};

export const openSettingsPageDisplayPanel = async (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
  event: CustomEvent,
) => {
  const {
    translations: { t },
  } = flowAPI;
  const { componentRef } = event.detail;

  const removeHandler: RemoveComponentHandler = async (compRef, identifier) => {
    await collapseRefComponent(editorSDK, compRef);

    return [identifier];
  };

  const addHandler: AddComponentHandler = async (identifier) => {
    await expandRefComponentByRole(
      editorSDK,
      HostWidgetId.SettingsPage,
      identifier.role,
    );

    return [identifier];
  };

  const elementsData = await getElementsData(editorSDK, t as TFunction);

  // TODO Add helpId
  return editorSDK.editor.openElementsPanel('', {
    widgetRef: componentRef,
    categoriesData: [],
    helpId: '',
    elementsData,
    addComponentHandler: addHandler,
    removeComponentHandler: removeHandler,
  });
};

export const openSettingsPagePresetPanel = async (
  editorSDK: FlowEditorSDK,
  event: CustomEvent,
  t: TFunction,
) => {
  const viewport = await editorSDK.editor.info.getCurrentViewport();

  // TODO: add helpId
  return createCustomPanelOnClick<LayoutPanelInitialData>({
    title: t('app.settings.layout.preset.title'),
    editorSDK,
    panelComp: 'SettingsPageLayoutPanel',
    height: 200,
    width: 288,
    helpId: '',
    initialData: { viewportType: viewport.type },
    widgetName: WidgetName.SettingsPage,
  })(event);
};

export const openProfileManageAndNavigatePanel = async ({
  editorSDK,
  event,
  t,
}: OpenManageAndNavigatePanelOptions) => {
  const initialData = await toMonitored(
    'split-app.profile.manage-and-navigate-panel.get-initial-data',
    () =>
      withRetry(() => getProfilePageManageAndNavigateInitialData(editorSDK), {
        methodName: 'getProfilePageManageAndNavigateInitialData',
      }),
  );

  // TODO: add helpId
  return createCustomPanelOnClick<ManageAndNavigatePanelInitialData>({
    title: t('app.settings.manage.menu.panel.title'),
    editorSDK,
    panelComp: 'ManageAndNavigatePanel',
    height: 521,
    width: 288,
    helpId: '',
    initialData,
    widgetName: WidgetName.Widget1,
  })(event);
};

export const openSettingsManageAndNavigatePanel = async ({
  editorSDK,
  event,
  t,
}: OpenManageAndNavigatePanelOptions) => {
  const initialData = await toMonitored(
    'split-app.settings.manage-and-navigate-panel.get-initial-data',
    () =>
      withRetry(() => getSettingsPageManageAndNavigateInitialData(editorSDK), {
        methodName: 'getSettingsPageManageAndNavigateInitialData',
      }),
  );

  // TODO: add helpId
  return createCustomPanelOnClick<ManageAndNavigatePanelInitialData>({
    title: t('app.settings.manage.menu.panel.title'),
    editorSDK,
    panelComp: 'ManageAndNavigatePanel',
    height: 521,
    width: 288,
    helpId: '',
    initialData,
    // We use a panel from the Widget1 here, to not duplicate
    widgetName: WidgetName.Widget1,
  })(event);
};
