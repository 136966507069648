import { FlowEditorSDK, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { fetchWidgetsStageData, TranslateFunc } from '@wix/bob-widget-services';

import { toMonitored } from '../../editor/services/monitor';
import { Experiment } from '../../constants';
import { withRetry } from '../../editor/services';

type FetchWidgetsStageDataOptions = {
  initialAppData: any; // issue on EP: https://jira.wixpress.com/browse/EP-4024
  editorSDK: FlowEditorSDK;
  translateFunc: TranslateFunc;
};

const MEMBER_PAGES_SUB_PAGES_KEY = 'wix.members_area.sub_pages.tab';

const monitoredFetchWidgetsStageData = (
  options: FetchWidgetsStageDataOptions,
) =>
  toMonitored('getAppManifest.fetchWidgetsStageData', () =>
    withRetry(() => fetchWidgetsStageData(options), {
      methodName: 'fetchWidgetsStageData',
      retryCount: 20,
      retryDelay: 400,
    }),
  );

export const createAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  _contextParams,
  flowAPI,
) => {
  const { appManifestBuilder } = options;
  const { t } = flowAPI.translations;

  const rawManifest = await monitoredFetchWidgetsStageData({
    initialAppData: options,
    editorSDK,
    translateFunc: t,
  });

  const enableSausageBar = flowAPI.experiments.enabled(
    Experiment.UseSausageBar,
  );

  if (enableSausageBar) {
    return appManifestBuilder.withJsonManifest(rawManifest).build();
  }

  return appManifestBuilder
    .withJsonManifest(rawManifest)
    .configurePagesTab((pagesTabBuilder) => {
      pagesTabBuilder.setSubPagesToHide('dynamicPagesNavBar', [
        MEMBER_PAGES_SUB_PAGES_KEY,
      ]);
    })
    .build();
};
