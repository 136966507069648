import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { ElementId, HostWidgetId } from '../../constants';
import { Nullable } from '../../types';
import { waitForMemberPageController } from '../controller/member-page-controller';
import {
  collapseRefComponent,
  expandRefComponentByRole,
  isRefComponentCollapsed,
} from '../editor-sdk-wrappers';
import { getProfilePageBobWidgetRef } from './page-ref';
import { getProfileCardSlot } from './slots';
import {
  addProfileCardWidget,
  alignProfileCardLayout,
  removeProfileCardWidget,
} from './profile-card';

export const showProfileCard = async (editorSDK: FlowEditorSDK) => {
  await expandRefComponentByRole(
    editorSDK,
    HostWidgetId.ProfilePage,
    ElementId.HeaderContainer,
  );
  await addProfileCardWidget(editorSDK);
  return alignProfileCardLayout(editorSDK);
};

const getHeaderContainerRef = async (
  editorSDK: FlowEditorSDK,
  controllerRef: ComponentRef,
): Promise<Nullable<ComponentRef>> => {
  const componentAPI = editorSDK.document.components;
  const [headerContainerCompRef] = await componentAPI.findAllByRole('', {
    controllerRef,
    role: ElementId.HeaderContainer,
  });

  return headerContainerCompRef;
};

export const maybeCollapseHeaderContainer = async (
  editorSDK: FlowEditorSDK,
) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const profileCardSlot = await getProfileCardSlot(editorSDK, widgetRef);
  if (profileCardSlot?.pluginInfo) {
    return;
  }

  const controllerRef = await waitForMemberPageController(editorSDK, widgetRef);
  const headerContainerCompRef = await getHeaderContainerRef(
    editorSDK,
    controllerRef,
  );
  if (!headerContainerCompRef) {
    return;
  }

  const isHeaderContainerCollapsed = await isRefComponentCollapsed(
    editorSDK,
    headerContainerCompRef,
  );
  if (isHeaderContainerCollapsed) {
    return;
  }

  return collapseRefComponent(editorSDK, headerContainerCompRef);
};

export const hideProfileCard = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const controllerRef = await waitForMemberPageController(editorSDK, widgetRef);
  const headerContainerCompRef = await getHeaderContainerRef(
    editorSDK,
    controllerRef,
  );

  if (headerContainerCompRef) {
    await removeProfileCardWidget(editorSDK);
    return collapseRefComponent(editorSDK, headerContainerCompRef);
  }
};
