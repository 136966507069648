import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import { MembersPublicAPI } from '../../../types';
import { Experiment, HistoryLabel } from '../../../constants';
import * as routes from '../../../editor/controller/controllers-routes';
import { updateRouteInConfig } from '../../../editor/controller/controllers-routes';
import { populateGlobalControllerWithRoutes } from '../data-sanitizer/global-controller-fixer';
import { getPublicApplications } from '../../../editor/services/applications';
import {
  hideProfileCard,
  showProfileCard,
} from '../../../editor/services/layout';
import {
  getMembersAreaPageRef,
  renameMembersAreaPage,
  updatePageUriSEO,
} from '../../../editor/services/page';
import {
  addWidgetsPlugins,
  getInstalledWidgetPlugins,
} from '../../../editor/services/slots';
import {
  monitoredTransactionFactory,
  toMonitored,
} from '../../../editor/services/monitor';
import { globalAppState, removeWidgets } from '../../../editor/services';
import {
  refreshApp,
  withHistoryFactory,
} from '../../../editor/editor-sdk-wrappers';
import { isProfilePageBobValid } from '../utils';
import { navigateToSection } from '../../../editor/services/navigation';

const initPublicAPI = (editorSDK: FlowEditorSDK): MembersPublicAPI => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const withHistory = withHistoryFactory(editorSDK);

  return {
    getMembersAreaPageRef: () =>
      toMonitored('public-api.get-members-area-page-ref', () =>
        getMembersAreaPageRef(editorSDK),
      ),
    getInstalledWidgetPlugins: () =>
      toMonitored('public-api.get-installed-widget-plugins', () =>
        getInstalledWidgetPlugins(editorSDK),
      ),
    addWidgetsPlugins: (integrationApplications, shouldNavigate) =>
      monitoredTransaction('public-api.add-widgets-plugins', () =>
        withHistory(HistoryLabel.WidgetsAdded, () =>
          addWidgetsPlugins({
            editorSDK,
            shouldNavigate,
            integrationApplications,
          }),
        ),
      ),
    getPublicApplications: () => getPublicApplications(editorSDK),
    getRoutes: () =>
      toMonitored('public-api.get-routes', () => routes.getRoutes(editorSDK)),
    updateRoute: (payload) =>
      monitoredTransaction('public-api.update-route', () =>
        withHistory(HistoryLabel.RouteUpdated, () =>
          updateRouteInConfig(editorSDK, payload),
        ),
      ),
    removeWidgets: (widgetsIds) =>
      monitoredTransaction('public-api.remove-widgets', () =>
        withHistory(HistoryLabel.WidgetsDeleted, () => {
          const flowAPI = globalAppState.getFlowAPI();
          const shouldUseSyncActions = flowAPI?.experiments?.enabled(
            Experiment.UseSyncDeleteActions,
          );

          return removeWidgets(editorSDK, widgetsIds, shouldUseSyncActions);
        }),
      ),
    renameMembersAreaPage: (newPageTitle) =>
      monitoredTransaction('public-api.rename-members-area-page', () =>
        renameMembersAreaPage(editorSDK, newPageTitle),
      ),
    updatePageUriSEO: (pageUriSEO) =>
      monitoredTransaction('public-api.update-page-uri-seo', () =>
        withHistory(HistoryLabel.PageUriSEOUpdated, () =>
          updatePageUriSEO(editorSDK, pageUriSEO),
        ),
      ),
    showProfileCard: () =>
      monitoredTransaction('public-api.show-profile-card', () =>
        showProfileCard(editorSDK),
      ),
    hideProfileCard: () =>
      monitoredTransaction('public-api.hide-profile-card', () =>
        hideProfileCard(editorSDK),
      ),
    uninstall: () =>
      monitoredTransaction('public-api.uninstall', () => {
        return editorSDK.application.uninstall('', { openConfirmation: false });
      }),
    refreshApp: () =>
      monitoredTransaction('public-api.refresh-app', () =>
        refreshApp(editorSDK),
      ),
    populateGlobalControllerWithRoutes: () =>
      monitoredTransaction(
        'public-api.populate-global-controller-with-routes',
        () => populateGlobalControllerWithRoutes(editorSDK),
      ),
    isProfilePageBobValid: () => isProfilePageBobValid(editorSDK),
    navigateToSection: async (widgetId: WidgetId) =>
      navigateToSection(editorSDK, widgetId),
  };
};

export const createPublicAPI = (editorSDK: FlowEditorSDK): MembersPublicAPI => {
  const getPublicAPI = (() => {
    let _publicAPI: MembersPublicAPI;
    return async () => {
      if (!_publicAPI) {
        _publicAPI = initPublicAPI(editorSDK);
      }

      if (!(await _publicAPI.isProfilePageBobValid())) {
        throw new Error(
          '[Profile Page BoB - Public API] Member Page or Widget is missing',
        );
      }

      return _publicAPI;
    };
  })();

  return {
    getMembersAreaPageRef: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersAreaPageRef();
    },
    getInstalledWidgetPlugins: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getInstalledWidgetPlugins();
    },
    addWidgetsPlugins: async (integrationApplication, shouldNavigate) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addWidgetsPlugins(
        integrationApplication,
        shouldNavigate,
      );
    },
    getPublicApplications: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getPublicApplications();
    },
    getRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getRoutes();
    },
    updateRoute: async (payload) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.updateRoute(payload);
    },
    navigateToSection: async (payload) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.navigateToSection(payload);
    },
    removeWidgets: async (widgetsIds) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.removeWidgets(widgetsIds);
    },
    renameMembersAreaPage: async (newPageTitle) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.renameMembersAreaPage(newPageTitle);
    },
    updatePageUriSEO: async (pageUriSEO) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.updatePageUriSEO(pageUriSEO);
    },
    showProfileCard: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.showProfileCard();
    },
    hideProfileCard: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.hideProfileCard();
    },
    uninstall: async () => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.uninstall();
    },
    refreshApp: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshApp();
    },
    populateGlobalControllerWithRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.populateGlobalControllerWithRoutes();
    },
    isProfilePageBobValid: async () => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.isProfilePageBobValid();
    },
  };
};
