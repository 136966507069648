import type { ContextParams } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { WidgetId } from '@wix/members-area-app-definitions';

import type { PrivateAPI } from '../../../types';
import {
  getLayoutPreset,
  openMemberSettingsBM,
  setLayoutPreset,
} from '../../../editor/services';
import { monitoredTransactionFactory } from '../../../editor/services/monitor';
import { withHistoryFactory } from '../../../editor/editor-sdk-wrappers';
import { HistoryLabel } from '../../../constants';
import { reorderMenuItems } from '../services/menu';
import { updateMenuItemLabel } from '../services/menu-items';
import {
  getSettingsPageLayoutPreset,
  setSettingsPageLayoutPreset,
} from '../services/layout';

const initPrivateAPI = (
  editorSDK: FlowEditorSDK,
  _contextParams: ContextParams,
): PrivateAPI => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const withHistory = withHistoryFactory(editorSDK);

  return {
    getLayoutPreset: () => getLayoutPreset(editorSDK),
    setLayoutPreset: (layoutPreset) => {
      const action = () => setLayoutPreset(editorSDK, layoutPreset);

      return monitoredTransaction(
        'ma-split.private-api.set-layout-preset',
        action,
      );
    },
    getSettingsPageLayoutPreset: () => getSettingsPageLayoutPreset(editorSDK),
    setSettingsPageLayoutPreset: (layoutPreset) => {
      const action = () => setSettingsPageLayoutPreset(editorSDK, layoutPreset);

      return monitoredTransaction(
        'ma-split.private-api.settings-page.set-layout-preset',
        action,
      );
    },
    updateMenusItemsOrder: async ({ menuId, sourceIndex, targetIndex }) => {
      const action = async () => {
        await withHistory(HistoryLabel.MenuItemsReordered, () =>
          reorderMenuItems({ editorSDK, menuId, sourceIndex, targetIndex }),
        );
      };

      return monitoredTransaction(
        'ma-split.private-api.update-members-sub-menu-items-order',
        action,
      );
    },
    updateMenusItemLabel: async (widgetId: WidgetId, newLabel: string) => {
      const action = () =>
        withHistory(HistoryLabel.MenuItemRenamed, () =>
          updateMenuItemLabel(editorSDK, widgetId, newLabel),
        );

      return monitoredTransaction(
        'ma-split.private-api.update-menus-item-label',
        action,
      );
    },
    openMemberSettingsBM: async (referralInfo) =>
      openMemberSettingsBM(editorSDK, referralInfo),
  };
};

export const createPrivateAPI = (
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
): PrivateAPI => {
  const getPrivateAPI = (() => {
    let _privateAPI: PrivateAPI;
    return async () => {
      if (_privateAPI) {
        return _privateAPI;
      }
      _privateAPI = initPrivateAPI(editorSDK, contextParams);
      return _privateAPI;
    };
  })();

  return {
    getLayoutPreset: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getLayoutPreset();
    },
    setLayoutPreset: async (layoutPreset) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.setLayoutPreset(layoutPreset);
    },
    getSettingsPageLayoutPreset: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getSettingsPageLayoutPreset();
    },
    setSettingsPageLayoutPreset: async (layoutPreset) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.setSettingsPageLayoutPreset(layoutPreset);
    },
    updateMenusItemsOrder: async (props) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemsOrder(props);
    },

    updateMenusItemLabel: async (widgetId, newLabel) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemLabel(widgetId, newLabel);
    },
    openMemberSettingsBM: async (referralInfo) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.openMemberSettingsBM(referralInfo);
    },
  };
};
