import { EditorReadyFn } from '@wix/yoshi-flow-editor';

import { uninstallIfProvisionedWithoutMembersArea } from '../common/uninstallation';
import {
  monitoredTransactionFactory,
  toMonitored,
} from '../../editor/services/monitor';
import { installMemberPage } from './installation';
import { addEventsListeners } from './listeners';
import { maybeSanitizeData } from './data-sanitizer/data-sanitizer';
import { isProfilePageBobValid } from './utils';

export const monitoredEditorReady: EditorReadyFn = (
  editorSDK,
  appDefinitionId,
  options,
  flowAPI,
) =>
  toMonitored('editorReady', async () => {
    if (!options.firstInstall) {
      const { uninstalled } = await uninstallIfProvisionedWithoutMembersArea(
        editorSDK,
      );
      if (uninstalled || !(await isProfilePageBobValid(editorSDK))) {
        return;
      }

      await maybeSanitizeData(editorSDK, flowAPI.experiments);
    }

    if (options.firstInstall) {
      const monitoredTransaction = monitoredTransactionFactory(editorSDK);

      await monitoredTransaction('install', () =>
        installMemberPage({
          editorSDK,
          options,
          appDefinitionId,
          flowAPI,
        }),
      );
    }

    await addEventsListeners(editorSDK, flowAPI);
  });
