import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import type { PrivateAPI } from '../../../types';
import { HistoryLabel } from '../../../constants';
import { updateMenusItemLabel } from '../../../editor/services/menus-wrapper';
import { monitoredTransactionFactory } from '../../../editor/services/monitor';
import { withHistoryFactory } from '../../../editor/editor-sdk-wrappers';

import {
  getLayoutPreset,
  openMemberSettingsBM,
  setLayoutPreset,
} from '../../../editor/services';
import { updateMembersSubMenuItemsOrder } from '../../../editor/services/members-sub-menu';

const initPrivateAPI = (editorSDK: FlowEditorSDK): PrivateAPI => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const withHistory = withHistoryFactory(editorSDK);

  return {
    getLayoutPreset: () => getLayoutPreset(editorSDK),
    setLayoutPreset: (layoutPreset) =>
      monitoredTransaction('private-api.set-layout-preset', () =>
        setLayoutPreset(editorSDK, layoutPreset),
      ),
    updateMenusItemsOrder: ({ sourceIndex, targetIndex }) =>
      monitoredTransaction(
        'private-api.update-members-sub-menu-items-order',
        async () => {
          await withHistory(HistoryLabel.MenuItemsReordered, () =>
            updateMembersSubMenuItemsOrder(editorSDK, sourceIndex, targetIndex),
          );
        },
      ),
    updateMenusItemLabel: (widgetId: WidgetId, newLabel: string) =>
      monitoredTransaction('private-api.update-menus-item-label', () =>
        withHistory(HistoryLabel.MenuItemRenamed, () =>
          updateMenusItemLabel(editorSDK, widgetId, newLabel),
        ),
      ),
    openMemberSettingsBM: (referralInfo) =>
      openMemberSettingsBM(editorSDK, referralInfo),
    getSettingsPageLayoutPreset: () => {
      throw new Error(
        'PrivateAPI.getSettingsPageLayoutPreset is supported only in Split MA V3',
      );
    },
    setSettingsPageLayoutPreset: () => {
      throw new Error(
        'PrivateAPI.setSettingsPageLayoutPreset is supported only in Split MA V3',
      );
    },
  };
};

export const createPrivateAPI = (editorSDK: FlowEditorSDK): PrivateAPI => {
  const getPrivateAPI = (() => {
    let _privateAPI: PrivateAPI;
    return async () => {
      if (_privateAPI) {
        return _privateAPI;
      }
      _privateAPI = initPrivateAPI(editorSDK);
      return _privateAPI;
    };
  })();

  return {
    getLayoutPreset: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getLayoutPreset();
    },
    setLayoutPreset: async (layoutPreset) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.setLayoutPreset(layoutPreset);
    },
    updateMenusItemsOrder: async (props) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemsOrder(props);
    },

    updateMenusItemLabel: async (widgetId: WidgetId, newLabel: string) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemLabel(widgetId, newLabel);
    },
    openMemberSettingsBM: async (referralInfo) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.openMemberSettingsBM(referralInfo);
    },
    getSettingsPageLayoutPreset: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getSettingsPageLayoutPreset();
    },
    setSettingsPageLayoutPreset: async (layoutPreset) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.setSettingsPageLayoutPreset(layoutPreset);
    },
  };
};
