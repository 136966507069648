import { toMonitored } from '../../editor/services/monitor';
import {
  addMyAccountWidget,
  addProfileCardWidget,
  addProfilePageBobWidget,
  alignProfileCardLayout,
} from '../../editor/services';
import { Experiment } from '../../constants';
import { PlatformContext } from '../../types';

export const installMemberPage = async ({
  editorSDK,
  options,
  appDefinitionId,
  flowAPI,
}: PlatformContext) => {
  await toMonitored('install.add-profile-page-bob-widget', () =>
    addProfilePageBobWidget({
      editorSDK,
      options,
      appDefinitionId,
      flowAPI,
    }),
  );

  const { experiments } = flowAPI;
  const shouldNotInstallProfileCard = experiments.enabled(
    Experiment.DoNotInitiallyInstallProfileCard,
  );

  if (shouldNotInstallProfileCard) {
    return toMonitored('install.add-my-account-widget', () =>
      addMyAccountWidget(editorSDK),
    );
  }

  await Promise.all([
    addProfileCardWidget(editorSDK),
    toMonitored('install.add-my-account-widget', () =>
      addMyAccountWidget(editorSDK),
    ),
  ]);
  await alignProfileCardLayout(editorSDK);
};
