import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';
import type { PageRef } from '@wix/platform-editor-sdk';

import { EDITOR_X_WIDGET_LAYOUT } from '../../constants/editor-x';
import { Experiment, PROFILE_WIDGET_OPTIONS } from '../../constants';
import { PlatformContext } from '../../types';
import { removeRoutes } from '../controller/controllers-routes';
import { addWidget, refreshApp } from '../editor-sdk-wrappers';
import { createProfilePage, findSectionInPage } from './page';
import { removeWidgetsMenusItems } from './menus-wrapper';
import { removeWidgetsPlugins } from './slots';
import { toMonitored, transactionWithConflictMonitor } from './monitor';
import { globalAppState } from './global-app-state';

const getSectionRef = (context: PlatformContext, pageRef: PageRef) => {
  const { environment } = context.flowAPI;

  if (environment.isEditorX) {
    return;
  }

  return findSectionInPage(context, pageRef);
};

export const addProfilePageBobWidget = async (context: PlatformContext) => {
  const { flowAPI, editorSDK } = context;
  const { translations, experiments } = flowAPI;

  const transaction = async () => {
    const profilePageRef = await toMonitored(
      'install.create-profile-page',
      () => {
        return createProfilePage({
          flowAPI,
          editorSDK,
          title: translations.t('app.page.title'),
          pageUriSEO: 'members-area',
        });
      },
    );
    const sectionRef = await toMonitored('install.get-section-ref', () =>
      getSectionRef(context, profilePageRef),
    );
    const containerRef = sectionRef || profilePageRef;

    return addWidget(editorSDK, {
      ...PROFILE_WIDGET_OPTIONS,
      layouts: EDITOR_X_WIDGET_LAYOUT,
      containerRef,
    });
  };

  const shouldAddWithTransaction = experiments.enabled(
    Experiment.WaitForMemberPageTransaction,
  );

  if (!shouldAddWithTransaction) {
    return transaction();
  }

  return transactionWithConflictMonitor(
    editorSDK,
    'add-profile-page-bob-widget',
    transaction,
  );
};

export const removeWidgets = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
  shouldUseSyncActions = false,
) => {
  const flowAPI = globalAppState.getFlowAPI();

  if (shouldUseSyncActions) {
    await removeWidgetsMenusItems(editorSDK, widgetsIds);
    await removeWidgetsPlugins(editorSDK, widgetsIds);
    await removeRoutes(editorSDK, widgetsIds);
  } else {
    await Promise.all([
      removeWidgetsMenusItems(editorSDK, widgetsIds),
      removeWidgetsPlugins(editorSDK, widgetsIds),
      removeRoutes(editorSDK, widgetsIds),
    ]);
  }

  if (
    flowAPI?.experiments.enabled(Experiment.VerticalDeletionRemoveRefreshApp)
  ) {
    return;
  }

  return refreshApp(editorSDK);
};
